jQuery.extend({
    Cookie: function(holder) {
        // // PUBLIC
        var _this = this;

        var _private = {
            // PRIVATE OBJECTS
            holder: null,

            // PRIVATE FUNCTIONS
            setup: function() {
                _private.holder = $(holder);
            },

            getCookie: function(name) {
                var nameEQ = name + '=';
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0)
                        return c.substring(nameEQ.length, c.length);
                }
                return null;
            },

            setCookie: function(name, value, days) {
                var expires = '';
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                    expires = '; expires=' + date.toUTCString();
                }
                document.cookie =
                    name + '=' + (value || '') + expires + '; path=/';
            },

            eraseCookie: function(name) {
                document.cookie = name + '=; Max-Age=-99999999;';
            },

            removeBanner: function() {
                $('.m-cookie-banner__button--accept').click(function() {
                    _private.setCookie('enable_cookie', 'cookie', 365);
                    $('.m-cookie-banner').addClass('m-cookie-banner--remove');
                });
            },

            declineBanner: function() {
                $('.m-cookie-banner__button--decline').click(function() {
                    $('.m-cookie-banner').addClass('m-cookie-banner--remove');
                });
            },

            hideBanner: function() {
                if (!_private.getCookie('enable_cookie')) {
                    $('.m-cookie-banner').addClass('m-cookie-banner--show');
                }
            }
        };

        function initialize() {
            _private.setup();
            _private.removeBanner();
            _private.hideBanner();
            _private.declineBanner();
        }
        $(document).ready(function() {
            initialize();
        });
    }
});

$(function() {
    var Cookie = new $.Cookie($('#js--m-cookie-banner'));
});
