jQuery.extend({

    mAccordion: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	: _this,
            accordionButton         : null,
            accordionContent        : null,
            // accordionItem           : null,


            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.accordionButton    =  $(holder).find('.m-accordion__header');
            },
            toggleContent:function() {

                _private.accordionButton.bind('click', function() {

                    if ($(this).parent().hasClass("collapsed")) {
                        $(this).parent().find(".m-accordion__content").slideUp(300);
                        $(this).parent().removeClass("collapsed");
                        $(this).parent().find("i").addClass("fa-plus");
                        $(this).parent().find("i").removeClass("fa-minus");

                    }
                    else {
                        $(this).parent().find(".m-accordion__content").slideDown(300);
                        $(this).parent().addClass("collapsed");
                        $(this).parent().find("i").addClass("fa-minus");
                        $(this).parent().find("i").removeClass("fa-plus");
                    }
                });

            }
        };
        function initialize()
        {
            _private.setup();
            _private.toggleContent();

        }
        $(document).ready(function()
        {
            initialize();
        });


        // WINDOW RESIZE
        function doResize() {
            _private.setup();

        }

        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 200);

        });
    }
});
$(function() {
    $( document ).ready(function()
    {
        $(".m-accordion").each(function(_index, _ell)
        {
            var mAccordion = new $.mAccordion($(_ell));
        });
    });
});
