jQuery.extend({

    mSearch: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	: _this,
            body                    : null,
            searchWrapper           : null,
            searchOpenBtn           : null,
            searchcloseBtn          : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.body           =  $(document).find('body');
                _private.searchWrapper     =  $(document).find('#l-search');
                _private.openSearchBtn     =  $(document).find('.m-search__btn');
                _private.closeSearchBtn    =  $(holder).find('.m-search__btn--close');
            },

            openSearchClickListener:function() {

                _private.openSearchBtn.bind('click', function() {
                    _private.openSearch();
                });

            },
            closeSearchClickListener:function() {

                _private.closeSearchBtn.bind('click', function() {
                    _private.closeSearch();
                });

            },
            openSearch:function() {
                if (_private.searchWrapper.hasClass("collapsed")) {
                    _private.searchWrapper.slideUp(200);
                    _private.searchWrapper.removeClass("collapsed");
                    _private.body.removeClass("search-collapsed");
                }

                else {
                    _private.searchWrapper.slideDown(200);
                    _private.searchWrapper.addClass("collapsed");
                    _private.body.addClass("search-collapsed");
                }
            },
            closeSearch:function() {
                _private.searchWrapper.slideUp(200);
                _private.searchWrapper.removeClass("collapsed");
                _private.body.removeClass("search-collapsed");
            }
        };
        function initialize()
        {
            _private.setup();
            _private.openSearchClickListener();
            _private.closeSearchClickListener();

        }
        $(document).ready(function()
        {
            initialize();
        });


        // WINDOW RESIZE
        function doResize() {
            _private.setup();

        }

        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 200);

        });
    }
});
$(function() {
    $( document ).ready(function()
    {
            var mSearch = new $.mSearch($(".m-search__collapse"));
    });
});
