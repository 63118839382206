jQuery.extend({

    mNavMobile: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	: _this,
            body                    : null,
            openNavBtn              : null,
            closeNavBtn             : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.openNavBtn       =  $(document).find('.m-nav-mobile__btn');
                _private.closeNavBtn      =  $(document).find('.m-nav-mobile__btn-close');
                _private.body             =  $('body');
                _private.searchWrapper    =  $(document).find('#l-search');
            },
            openNavClickListener:function() {

                _private.openNavBtn.bind('click', function() {
                    _private.openNav();
                });

            },
            closeNavClickListener:function() {

                _private.closeNavBtn.bind('click', function() {
                    _private.closeNav();
                });

            },
            openNav:function() {
                $(holder).addClass("collapsed");
                _private.body.addClass("nav-collapsed");
            },
            closeNav:function() {
                $(holder).removeClass("collapsed");
                _private.body.removeClass("nav-collapsed");

                //Close search if open
                if (_private.searchWrapper.hasClass("collapsed")) {
                    _private.searchWrapper.slideUp(200);
                    _private.searchWrapper.removeClass("collapsed");
                    _private.body.removeClass("search-collapsed");
                }
            }
        };
        function initialize()
        {
            _private.setup();
            _private.openNavClickListener();
            _private.closeNavClickListener();

        }
        $(document).ready(function()
        {
            initialize();
        });


        // WINDOW RESIZE
        function doResize() {
            _private.setup();

        }

        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 200);

        });
    }
});
$(function() {
    $( document ).ready(function()
    {
            var mNavMobile = new $.mNavMobile($("#m-nav-mobile__wrapper"));
    });
});
