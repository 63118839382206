jQuery.extend({
	
	mNavTabs: function(holder){
	
		// VERSION : 141022
		
		// PUBLIC 
		var _this 				= this;
		
		var _private = {
			
			// PRIVATE OBJECTS
			holder:          	null,
			
			// PRIVATE FUNCTIONS
			setup:function() {
				_private.holder = holder;

				
				_private.holder.find( ".m-nav-tabs__dropdown" ).each(function() {
					if ( $(this).find("li").length > 0) {
						$(this).parent().find(".m-nav-tabs__dropdown-toggle").show();
						$(this).parent().addClass("has-dropdown");
					}
				});

				_private.holder.find( ".m-nav-tabs__dropdown-toggle" ).each(function() {
					

					$(this).click(function(event) {
						var ell = $(this).parent();
						_private.toggleDropdown(ell);
					});

				});
				

			},
			toggleDropdown:function(ell) {

				if ( $(ell).hasClass("dropdown-open")) {
					$(ell).removeClass("dropdown-open");
				}
				else {
					$(ell).addClass("dropdown-open");
				}
			}
		}
		
		function initialize()
		{
			_private.setup();
		}
		
		// INITIALIZE APPLICATION
		$(document).ready(function() 
		{
			initialize();
		});
	}
});
$(function()
{
	var mNavTabs = new $.mNavTabs($(".m-nav-tabs"));
});