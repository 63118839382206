jQuery.extend({
	
	mNavSub: function(holder){
	
		// VERSION : 141022
		
		// PUBLIC 
		var _this 				= this;
		
		var _private = {
			
			// PRIVATE OBJECTS
			
			// PRIVATE FUNCTIONS
			setup:function() {
				if($(holder).find(".menu>li").length > 0) {
					
					$(holder).find(".menu>li").each(function(index, element) {
						
						if($(element).find("ul.sub-menu").children().length > 0) {
							
		                    $("<div class='icon_arrow arrowdown'></div>").insertAfter($(element).find("a:eq(0)"));
							
							$(element).find("ul.sub-menu").find("li").each(function(subindex, subelement) {
							
								$(subelement).find("a").html("<p>"+$(subelement).find("a").html()+"</p>");
							});
							
		                    $(element).find("a:eq(0)").bind("click", _private.toggleElement);
	                    }
	                });
				}

				//START FIXES/ADJUSTMENTS FOR MOBILE MENU
				$(document).find(".menuloggedin .o-dropdown__toggle").each(function(index, element) {
					if($(element).parent().find(" > div > ul ").children().length == 0) {
						$(this).hide();
					}
				});

				$(document).find(".m-nav-logged-in").each(function(index, element) {

					$(this).find(" > .menu > li").each(function(index, element) {

						if($(element).find("ul.menu").children().length > 0) {
							$(this).append("<div class='toggle-sub-mobile'><div class='o-triangle o-triangle--bottom'></div></div>");

							$(this).find(".sub-menu").each(function(index, element) {
								$(element).parent().append("<div class='toggle-sub-sub-mobile'><div class='o-triangle o-triangle--bottom'></div></div>");
							});
						}

					});

					$(this).find(".toggle-sub-mobile").each(function(index, element) {
						$(this).bind("click", _private.toggleElementMobile);
					});

					$(this).find(".toggle-sub-sub-mobile").each(function(index, element) {
						$(this).bind("click", _private.toggleSubMobile);
					});

				});
				//END FIXES/ADJUSTMENTS FOR MOBILE MENU
			},
			setProperties:function() {
				var _url = location.pathname.split("http://www.locustraining.org");
				var _closeOnInit = true;
				
				if($(holder).find(".menu>li").length > 0) {
					
					// close submenu on initation
					$(holder).find(".menu>li").each(function(index, element) {
					
						$(element).find("ul.sub-menu").children().each(function(index, element) {
							if($(element).find('a').attr('href') == _url) {
								_closeOnInit = false;
							}
						});
					
						if(_closeOnInit) {
						
							if($(element).find("ul.sub-menu").children().length > 0) {
							
								$(this).find(".icon_arrow").toggleClass("arrowdown");
								$(this).find(".icon_arrow").toggleClass("arrowup");
								$(this).find('ul.sub-menu').toggle();
							}
						}
						_closeOnInit = true;
	                });
				}
				
				$(holder).find('a').each(function(index, element) {
					
					if($(element).attr('href') == '#') {
						$(element).attr('href','javascript:void(0)');
					}
				});
			},
			toggleElement:function() {
				$(this).parent().find(".icon_arrow").toggleClass("arrowdown");
				$(this).parent().find(".icon_arrow").toggleClass("arrowup");
				$(this).parent().find('ul.sub-menu').slideToggle(300);
			},
			toggleElementMobile:function() {
				$(this).parent().find(" > div > .menu").slideToggle(300);
				$(this).find(".o-triangle").toggleClass("o-triangle--top");
				$(this).find(".o-triangle").toggleClass("o-triangle--bottom");
			},
			toggleSubMobile:function() {
				$(this).parent().find(" .sub-menu").slideToggle(300);
				$(this).find(".o-triangle").toggleClass("o-triangle--top");
				$(this).find(".o-triangle").toggleClass("o-triangle--bottom");
			}
		}
		
		function initialize()
		{
			_private.setup();
			_private.setProperties();
		}
		
		// INITIALIZE APPLICATION
		$(document).ready(function() 
		{
			initialize();
		});
	}
});
$(function()
{
	var mNavSub = new $.mNavSub($(".m-nav-sub"));
});