jQuery.extend({

    mFooter: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	: _this,
            footerUpBtn             : null,


            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.footerUpBtn    =  $(holder).find('.m-footer__up__btn');
            },
            toggleContent:function() {

                _private.footerUpBtn.bind('click', function() {

                    $("body").animate({scrollTop:0}, '500');
                });

            }
        };
        function initialize()
        {
            _private.setup();
            _private.toggleContent();

        }
        $(document).ready(function()
        {
            initialize();
        });


        // WINDOW RESIZE
        function doResize() {
            _private.setup();

        }

        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 200);

        });
    }
});
$(function() {
    $( document ).ready(function()
    {
        $(".m-footer").each(function(_index, _ell)
        {
            var mFooter = new $.mFooter($(_ell));
        });
    });
});
