jQuery.extend({

    oDropdown: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	: _this,
            dropdownToggle          : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.dropdownToggle    =  $(holder).find('.o-dropdown__toggle');
            },
            toggleContent:function() {

                _private.dropdownToggle.bind('click', function() {

                    if ($(this).parent().hasClass("collapsed")) {
                        $(this).parent().find(" > div > .menu").slideUp(300);
                        $(this).parent().find(" > .m-nav-list > div > .menu").slideUp(300);
                        $(this).parent().removeClass("collapsed");
                    }
                    else {
                        $(this).parent().find(" > div > .menu").slideDown(300);
                        $(this).parent().find(" > .m-nav-list > div > .menu").slideDown(300);

                        
                        $(this).parent().addClass("collapsed");
                    }
                });

            }
        };
        function initialize()
        {
            _private.setup();
            _private.toggleContent();

        }
        $(document).ready(function()
        {
            initialize();
        });


        // WINDOW RESIZE
        function doResize() {
            _private.setup();

        }

        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 200);

        });
    }
});
$(function() {
    $( document ).ready(function()
    {
        $(".o-dropdown").each(function(_index, _ell)
        {
            var oDropdown = new $.oDropdown($(_ell));
        });
    });
});
